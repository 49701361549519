import axios from "axios";

export default {
    async getLeads(userId) {
        const data = new FormData();
        data.append("user_id", userId);

        //const response = await axios.post(`http://192.168.11.141/firegui_gmsystem/sales/leads/getFilteredLeads`, data, {
        const response = await axios.post(`sales/leads/getFilteredLeads`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async getCustomers() {
        const data = new FormData();
        data.append("where[customers_type]", "1");
        data.append("orderby", "customers_full_name");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/customers", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response.data;
    },

    async updateLeadColumn(data) {
        const formData = new FormData();
        formData.append("leadInfo", JSON.stringify(data));

        const response = await axios.post(`sales/leads/updateLead`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async loadMessages(customer_id: string) {
        const formData = new FormData();
        formData.append("where[leads_messages_customer_id]", customer_id);
        formData.append("orderby", "leads_messages_creation_date");
        formData.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/leads_messages`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    async createMessage(data) {
        //const response = await axios.post(`http://192.168.11.141/firegui_gmsystem/rest/v1/create/leads_messages`, data, {
        const response = await axios.post(`rest/v1/create/leads_messages`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data;
    },

    async creaLead(data) {
        const formData = new FormData();
        formData.append("new_lead", JSON.stringify(data));

        //const response = await axios.post(`http://192.168.11.141/firegui_gmsystem/sales/leads/createLead`, formData, {
        const response = await axios.post(`sales/leads/createLead`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async saveLead(data) {
        const response = await axios.post("rest/v1/create/leads", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    async getCustomerLeads(user_id: string = null, customer_id: string) {
        /* const formData = new FormData();
        formData.append("where[leads_customer_id]", customer_id);
        formData.append("orderby", "leads_recall_date");
        formData.append("orderdir", "asc");*/

        const formData = new FormData();
        formData.append("customer_id", customer_id);
        formData.append("user_id", user_id);
        //const response = await axios.post(`http://192.168.11.141/firegui_gmsystem/sales/app/getCustomerLead`, formData, {
        const response = await axios.post(`sales/app/getCustomerLead`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response;
    },

    async editRecallDate(lead_id, recall_date) {
        const formData = new FormData();
        formData.append("leads_recall_date", recall_date);

        const response = await axios.post(`rest/v1/edit/leads/${lead_id}`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response;
    },
};
